import React, { useEffect, useState } from 'react';
import Footer from '../components/Footer';
import Navbar from '../components/Navbar';

export default function MyOrder() {
    const [orderData, setOrderData] = useState([]);

    const fetchMyOrder = async () => {
        console.log(localStorage.getItem('userEmail'));
        await fetch("http://localhost:5000/api/myOrderData", {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                email: localStorage.getItem('userEmail')
            })
        }).then(async (res) => {
            let response = await res.json();
            setOrderData(response.orderData ? response.orderData.order_data : []);
        });
    };

    useEffect(() => {
        fetchMyOrder();
    }, []);

    return (
        <div>
            <div>
                <Navbar />
            </div>

            <div className='container'>
                <div className='row'>
                    {orderData.length > 0 ? orderData.slice(0).reverse().map((order, index) => {
                        return (
                            <div key={index} className='order'>
                                {order.map((arrayData, idx) => (
                                    <div key={idx}>
                                        {arrayData.Order_date ? 
                                            <div className='m-auto mt-5'>
                                                <h4>Order Date: {new Date(arrayData.Order_date).toLocaleDateString()}</h4>
                                                <hr />
                                            </div>
                                        : 
                                            <div className='col-12 col-md-6 col-lg-3'>
                                                {/* {console.log(arrayData)} */}
                                                <div className="card mt-3" style={{ width: "16rem", maxHeight: "360px" }}>
                                                    <img src={arrayData.img} className="card-img-top" alt="Food Item" style={{ height: "120px", objectFit: "fill" }} />
                                                    <div className="card-body">
                                                        <h5 className="card-title">{arrayData.name}</h5>
                                                        <div className='container w-100 p-0' style={{ height: "38px" }}>
                                                            <span className='m-1'>{arrayData.qty}</span>
                                                            <span className='m-1'>{arrayData.size}</span>
                                                            <div className=' d-inline ms-2 h-100 w-20 fs-5'>
                                                                ₹{arrayData.price}/-
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                ))}
                            </div>
                        );
                    }) : <div className='m-5 w-100 text-center fs-3'>No orders found!</div>}
                </div>
            </div>

            <Footer />
        </div>
    );
}
